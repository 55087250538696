@clndrWidth: 900px;
@clndrControlsWidth: 70px;
@clndrHorizPadding: 30px;
@clndrGridWidth: (@clndrWidth - (2 * @clndrControlsWidth) - (2 * @clndrHorizPadding));
@clndrCellWidth: (@clndrGridWidth / 7);

.clndrLoading {
	text-align: center;
	padding: 16em 0;
}

.clndr-interactive {
	display: block;
	margin: 0 auto;
	width: @clndrWidth;
	display: table;
	border-collapse: collapse;
	background-color: white;

	.clndr-controls {
		width: @clndrControlsWidth;
		height: 100%;
		border: 1px solid @gray-lighter;
		display: table-cell;
		position: relative;

		.clndr-previous-button, .clndr-next-button {
			cursor: pointer;
			width: @clndrControlsWidth;
			height: 100px;
			width: 70px;
			top: 50%;
			margin-top: -50px;
			position: absolute;

			-webkit-user-select: none; /* Chrome/Safari */
			-moz-user-select: none; /* Firefox */
			-ms-user-select: none; /* IE10+ */

			span {
				padding: 40px 0 0 20px;
				text-align: center;
				font-size: 24px;
				color: darken(@gray-lighter, 5%);
			}

			&:hover {
				span {
					color: @gray-light;
				}
			}
		}
	}

	.clndr-grid {
		width: @clndrGridWidth;
		display: table-cell;
		background-color: white;
		border: 1px solid @gray-lighter;
		padding: 10px @clndrHorizPadding 30px @clndrHorizPadding;

		.month {
			padding: 1em 0;
			text-align: center;
			width: @clndrGridWidth;
			position: relative;

			.action-toggle-month {
				cursor: pointer;
				position: absolute;
				top: 15px;
				right: 15px;
			}

			.mandays-count-in-month {
				position: absolute;
				top: 15px;
				left: 15px;
				color: @gray-light;
			}

		}

		.days-of-the-week {
			width: @clndrGridWidth;

			.header-day {
				float: left;
				width: @clndrCellWidth;
				padding: 1em 0;
				text-align: center;
				color: @gray-light;
				font-size: 12px;
			}
		}
	}

	.day, .empty {
		float: left;
		width: @clndrCellWidth;
		padding: .5em 0;
		position: relative;
		text-align: center;

		&.today {
			background-color: lighten(@gray-lighter, 5%);
			border-radius: 10px;
		}

		.action {
			background-color: white;
			border: 2px solid @gray-lighter;
			border-radius: 100%;
			color: #fff;
			cursor: pointer;
			width: 60px;
			height: 60px;
			margin: auto;
			text-align: center;
			position: relative;

			.dof {
				position: absolute;
				top: 36px;
				left: -8px;
				border: 2px solid @gray-lighter;
				background-color: lighten(@gray-lighter, 5%);
				color: @gray-light;
				border-radius: 100%;
				width: 25px;
				height: 25px;
				font-size: 11px;
				line-height: 21px;
			}

			&:hover {
				border: 2px solid @brand-primary;

				.dof {
					border: 2px solid white;
					background-color: @brand-primary;
					color: white;
				}
			}
		}

		.action.present,
		.action.leave {
			background: @brand-primary url('/img/clndr-bg-present.png');
			border: none;
			border: 2px solid @brand-primary;

			.dof {
				background: @brand-primary;
				border: 2px solid white;
				color: white;
			}
		}

		.action.present {
			background: @brand-primary url('/img/clndr-bg-present.png');
			background-position: center center;
		}

		.action.leave {
			background: @brand-warning url('/img/clndr-bg-leave.png');
			background-position: center center;
			border: 2px solid @brand-warning;

			.dof {
				background: @brand-warning;
			}
		}

		&.adjacent-month {
			opacity: 0.3;
		}
	}

	.empty {
		background-color: transparent;

		.action {
			display: none;
		}
	}
}

/* Disabled state */

.clndr-interactive {
	.disabledDay {
		background: lighten(@gray-lighter, 5%) url('/img/clndr-bg-holiday.png');
		background-position: center center;
		border: 2px solid @gray-lighter;

		&:hover {
			border: 2px solid @gray-lighter;

			.dof {
				border: 2px solid @gray-lighter;
				background-color: lighten(@gray-lighter, 5%);
				color: @gray-light;
			}
		}
	}

	.day .action.holiday {
		.disabledDay();
	}

	.calendar-dow-5,
	.calendar-dow-6 {
		.action {
			.disabledDay();
		}
	}
}

/* Mandays */

.clndr-mandays {
	margin: auto;
	background: white;
	#gradient.vertical(lighten(@gray-lighter, 5%); white; 0%; 30%);
	border: 1px solid @gray-lighter;
	border-top: none;
	width: 300px;
	text-align: center;
	font-size: 24px;
	padding: .5em 1em;
	color: @brand-primary;
}

/* Mandays */

.clndr-move-stay {
	margin-top: 3em;
}
