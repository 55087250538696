@printClndrBorder: 1px solid @gray-lighter;
@printClndrBoxWidth: 28px;
@printClndrBoxHeight: 44px;
@printClndrWidth: (@printClndrBoxWidth * 7) + 2;

.clndr-print-container {

}

.clndr-print {
	max-width: @printClndrWidth;
	margin: 3px;
	float: left;

	.clndr-grid {
		text-align: center;
		border: @printClndrBorder;
		border-collapse: collapse;
		font-size: 11px;

		.clearfix();

		.month {
			padding: 10px;
		}

		.header-day {
			float: left;
			width: @printClndrBoxWidth;
			height: @printClndrBoxHeight;
			background: @gray-lighter;
			padding-top: 14px;
		}

		.dof {
			color: @gray-light;
			text-align: center;
			font-size: 11px;
		}

		.day {
			float: left;
			width: @printClndrBoxWidth;
			height: @printClndrBoxHeight;
			padding-top: 5px;
			font-size: 15px;
		}

		.empty, .adjacent-month {
			float: left;
			width: @printClndrBoxWidth;
			height: @printClndrBoxHeight;
			background: @gray-lighter;
		}
	}
}
