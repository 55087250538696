@import "bootstrap/less/bootstrap";

@import "_variables";
@import "_theme";
@import "_calendar";
@import "_calendar_print";
@import "_datepicker";

.container {
	width: 100% ;
	padding-left: 30px ;
	padding-right: 30px ;
	max-width: 1600px;
	min-width: 1140px;
}

.container-narrow {
	max-width: 1200px;
	padding-left: 15px;
	padding-right: 15px;
}


.container-drop {
	margin-top: 5.5em;
}

.app-header {
	margin: 0 0 1em 0;
	height: 80px;

	h2 > .btn {
		margin-left: 1em;
	}

	.tools {
		margin: 8px;
		margin-top: 1.75em;

		.filter {
			padding-right: 2em;

			select {
				width: 200px;
				margin-left: 1em;
			}
		}
	}
}

.clndr-app-header {
	width: 900px;
	margin-left: auto;
	margin-right: auto;

	h2 {
		float: left;
	}

	.dates {
		float: left;
		padding-top: 2.2em;
		padding-left: 2.2em
	}

	span.light {
		font-weight: 300;
		color: @gray-light;
	}
}

.app-footer {
	margin: 5.5em 0;
	color: @gray-light;
}

.form {
	background-color: white;
	margin: 1em 0;
	padding: 1em 2em 2em 2em;

	h2 {
		margin-bottom: 1em;
	}
}

.panel-archive,
.panel-add-holiday {
	.form-control {
		width: 320px;
	}

	/* fix positioning of datepicker widget */
	.bootstrap-datetimepicker-widget {
		left: 40px !important;
	}
}

.no-documentation {
	color: @brand-danger;
}

.show-all {
	padding-top: 2.5em;
}

.demo-version {
	h3 {
		margin-top: .25em;
	}
}

/* Error Page */

.error {
	margin-top: 5.5em;

	h1 {
		font-weight: 300;
		color: red;
	}

	span {
		font-size: 30px;
	}
}

/* Print */

@media print {
	a[href]:after {
		content: none;
	}

	table.table {
		th, tr, td {
			padding: 0 !important;
		}
	}
}

body.print {
	background: white;
	padding: 2em;
}
