// Flatly 3.3.4
// Bootswatch
// -----------------------------------------------------

// Navbar =====================================================================

.navbar-buttons {
  padding: 0 4px 0 16px;
}

.navbar-brand {
  padding: 14px 15px;
}

.navbar {
  border: 0;
  border-bottom: 1px solid @gray-lighter;

  &-default {

    .navbar-nav > li {
      margin: 0 .25em;
    }

    .navbar-nav > li > a,
    .navbar-nav > li > a:hover,
    .navbar-nav > li > a:focus {
      border-bottom: 3px solid white;
    }

    .navbar-nav > .open > a,
    .navbar-nav > .open > a:hover,
    .navbar-nav > .open > a:focus {
      border-bottom: 3px solid @brand-primary;
    }

    .navbar-nav > .active > a,
    .navbar-nav > .active > a:hover,
    .navbar-nav > .active > a:focus {
      border-bottom: 3px solid @brand-primary;
    }

    .badge {
      background-color: #fff;
      color: @navbar-default-bg;
    }
  }

  &-inverse {

    .badge {
      background-color: #fff;
      color: @navbar-inverse-bg;
    }
  }

  &-brand {
    line-height: 1;
  }
}

// Buttons ====================================================================

.btn {
  border-width: 2px;
}

.btn:active {
  .box-shadow(none);
}

.btn-group.open .dropdown-toggle {
  .box-shadow(none);
}

// Typography =================================================================

.text-primary,
.text-primary:hover {
  color: @brand-primary;
}

.text-success,
.text-success:hover {
  color: @brand-success;
}

.text-danger,
.text-danger:hover {
  color: @brand-danger;
}

.text-warning,
.text-warning:hover {
  color: @brand-warning;
}

.text-info,
.text-info:hover {
  color: @brand-info;
}

// Tables =====================================================================

table,
.table {

  .dropdown-menu a {
    text-decoration: none;
  }

  td.table-buttons {
    padding: 8px;
  }

  .table-centered {
    text-align: center;
  }

  .table-edit-controls {
    min-width: 110px;
  }

   > thead > tr > th {
    //background-color: @brand-primary;
    //color: white;
    font-weight: 400;
    //font-size: @font-size-small;
    color: @brand-primary;
    padding: 16px 8px;
   }

  .success,
  .warning,
  .danger,
  .info {
    color: #fff;

    > th > a,
    > td > a,
    > a {
      color: #fff;
    }
  }

  > thead > tr > th,
  > tbody > tr > th,
  > tfoot > tr > th,
  > thead > tr > td,
  > tbody > tr > td,
  > tfoot > tr > td {
    border: none;
  }

  &-bordered > thead > tr {
    border-bottom: 1px solid darken(@table-border-color, 10%);
  }

  &-bordered > tbody > tr,
  &-bordered > tfoot > tr {
    border: 1px solid @table-border-color;
  }
}

// Forms ======================================================================

.control-label {
  font-weight: 400;
}

.form-control,
input, {
  border-width: 2px;
  .box-shadow(none);

  &:focus {
    .box-shadow(none);
  }
}

.has-warning {
  .help-block,
  .control-label,
  .radio,
  .checkbox,
  .radio-inline,
  .checkbox-inline,
  .form-control-feedback {
    color: @brand-warning;
  }

  .form-control,
  .form-control:focus {
    border: 2px solid @brand-warning;
  }

  .input-group-addon {
    border-color: @brand-warning;
  }
}

.has-error {
  .help-block,
  .control-label,
  .radio,
  .checkbox,
  .radio-inline,
  .checkbox-inline,
  .form-control-feedback {
    color: @brand-danger;
  }

  .form-control,
  .form-control:focus {
    border: 2px solid @brand-danger;
  }

  .input-group-addon {
    border-color: @brand-danger;
  }
}

.has-success {
  .help-block,
  .control-label,
  .radio,
  .checkbox,
  .radio-inline,
  .checkbox-inline,
  .form-control-feedback {
    color: @brand-success;
  }

  .form-control,
  .form-control:focus {
    border: 2px solid @brand-success;
  }

  .input-group-addon {
    border-color: @brand-success;
  }
}

// Navs =======================================================================

.nav {
  .open > a,
  .open > a:hover,
  .open > a:focus {
    border-color: transparent;
  }
}

.pager {
  a,
  a:hover {
    color: #fff;
  }

  .disabled {
    &>a,
    &>a:hover,
    &>a:focus,
    &>span {
      background-color: @pagination-disabled-bg;
    }
  }
}

// Indicators =================================================================

.close {
  color: #fff;
  text-decoration: none;
  opacity: 0.4;

  &:hover,
  &:focus {
    color: #fff;
    opacity: 1;
  }
}

.alert {

  .alert-link {
    color: #fff;
    text-decoration: underline;
  }
}

// Progress bars ==============================================================

.progress {
  height: 10px;
  .box-shadow(none);
  .progress-bar {
    font-size: 10px;
    line-height: 10px;
  }
}

// Containers =================================================================

.well {
  .box-shadow(none);
}

a.list-group-item {

  &.active,
  &.active:hover,
  &.active:focus {
    border-color: @list-group-border;
  }

  &-success {
    &.active {
      background-color: @state-success-bg;
    }

    &.active:hover,
    &.active:focus {
      background-color: darken(@state-success-bg, 5%);
    }
  }

  &-warning {
    &.active {
      background-color: @state-warning-bg;
    }

    &.active:hover,
    &.active:focus {
      background-color: darken(@state-warning-bg, 5%);
    }
  }

  &-danger {
    &.active {
      background-color: @state-danger-bg;
    }

    &.active:hover,
    &.active:focus {
      background-color: darken(@state-danger-bg, 5%);
    }
  }
}

.panel {
  &-default {
    .close {
      color: @text-color;
    }
  }
}

/*
.panel-heading {
  a {
    color: lighten(@brand-primary, 30%);

    &:hover {
      color: white;
    }
  }
}
*/

.modal {
  .close {
    color: @text-color;
  }
}

.popover {
  color: @text-color;
}

.bs-callout {
  padding: 20px;
  margin: 20px 0;
  border: 1px solid @gray-lighter;
  border-left-width: 5px;
  border-radius: 3px;
  background: white;
}

.bs-callout h4 {
  margin-top: 0;
  margin-bottom: 5px;
}

.bs-callout p:last-child {
  margin-bottom: 0;
}

.bs-callout code {
  border-radius: 3px;
}

.bs-callout+.bs-callout {
  margin-top: -5px;
}

.bs-mixin(@name, @callout-color, @font-color) {
  .@{name},
  &.@{name} h4 {
    border-left-color: @callout-color;
    color: @font-color;
  }
}

.bs-mixin(bs-callout-default, @gray-lighter, darken(@gray-lighter, 50%));
.bs-mixin(bs-callout-primary, @brand-primary, @brand-primary);
.bs-mixin(bs-callout-info, @brand-info, @brand-info);
.bs-mixin(bs-callout-danger, @brand-danger, @brand-danger);
.bs-mixin(bs-callout-warning, @brand-warning, @brand-warning);
.bs-mixin(bs-callout-success, @brand-success, @brand-success);
